import { useRouter } from "next/router";
import { arrayOf, node, shape, string } from "prop-types";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { DomainType, SubFooterType } from "~types";

export const NavigationContext = createContext({});

export const NavigationProvider = ({
  children,
  directory,
  pages,
  domains,
  subFooter,
  defaultDomain,
}) => {
  const router = useRouter();
  const directoryChangedKey = directory?.id;
  const domainsChangedKey = domains?.map(({ id }) => id).join("-");
  const [currentDomain, setCurrentDomain] = useState(defaultDomain || null);

  const handleSelectDomain = useCallback(
    (domainId) => {
      const newDomain = domains?.find(({ id }) => id === domainId);
      setCurrentDomain(newDomain);
    },
    [domains],
  );

  // This useEffect should only trigger if the language, directory or domains ACTUALLY change
  // therefore we're checking the ids of the objects in the array, not the object references themselves
  useEffect(() => {
    if (!domains?.length) {
      return;
    }

    const domainLanding = domains.find(
      (domain) =>
        domain.landingPage?.length && domain.landingPage[0].slug === directory,
    );

    setCurrentDomain(domainLanding || domains[0]);
    // purposefully disabled because we want to check ids, not the object references themselves
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.locale, directoryChangedKey, domainsChangedKey]);

  const value = useMemo(
    () => ({
      domains,
      directory,
      pages,
      currentDomain,
      handleSelectDomain,
      subFooter,
    }),
    [currentDomain, directory, domains, handleSelectDomain, pages, subFooter],
  );

  return (
    currentDomain && (
      <NavigationContext.Provider value={value}>
        {children}
      </NavigationContext.Provider>
    )
  );
};

NavigationProvider.propTypes = {
  children: node,
  domains: arrayOf(shape(DomainType)),
  directory: string,
  pages: arrayOf(string),
  subFooter: shape(SubFooterType),
  defaultDomain: shape(DomainType),
};
