import { func, number, shape, string } from "prop-types";
import { useContext, useEffect, useState } from "react";

import Icon from "~components/Icon";
import { ToastContext } from "~contexts/Toaster";
import classnames from "~utils/classnames";

import classes from "./Toaster.module.scss";

const Toaster = () => {
  const { toasts, removeToast } = useContext(ToastContext);

  if (toasts.length === 0) {
    return null;
  }

  return (
    <div className={classes.toastContainer}>
      {toasts.map((toast, index) => (
        <ToastMessage
          key={toast.id}
          toast={toast}
          removeToast={removeToast}
          index={index}
        />
      ))}
    </div>
  );
};

const ToastMessage = ({ toast, removeToast }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      setTimeout(() => {
        removeToast(toast.id);
      }, 500);
    }, 4500);
    return () => clearTimeout(timer);
  }, [toast.id, removeToast]);

  return (
    <div
      key={toast.id}
      className={classnames(
        classes.toast,
        isVisible ? classes.visible : classes.hidden,
      )}
    >
      <div className={classes.background} />
      <Icon type={toast.icon} className={classes.icon} />
      <div className={classes.content}>
        <h4 className={classes.title}>{toast.title}</h4>
        <p
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: toast.description }}
        />
      </div>
    </div>
  );
};

ToastMessage.propTypes = {
  toast: shape({
    id: number.isRequired,
    icon: string.isRequired,
    title: string.isRequired,
    description: string.isRequired,
  }).isRequired,
  removeToast: func.isRequired,
  index: number.isRequired,
};

export default Toaster;
