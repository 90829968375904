import { useRouter } from "next/router";
import { node } from "prop-types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { NavigationContext } from "./NavigationContext";

export const PRIMARY_THEME = "default";
const SECOND_THEME = "750";

const allowedLanguages = ["nl", "en"];
const excludedPages = [
  "/tickets",
  "/tickets/i-amsterdam-city-card",
  "/tickets/i-amsterdam-city-card/checkout",
  "/tickets/canal-cruise-ticket",
  "/tickets/canal-cruise-ticket/checkout",
];

/**
 *
 * @param {{ showTheme?: boolean }} domain
 * @param {string} locale
 * @param {string} [pageSlug]
 * @returns
 */
export function is750ThemeEnabled(domain, locale, pageSlug = null) {
  if (!allowedLanguages.includes(locale)) {
    return false;
  }
  if (!domain?.showTheme) {
    return false;
  }
  if (pageSlug && excludedPages.includes(pageSlug)) {
    return false;
  }
  return true;
}

const ThemeContext = createContext({
  theme: PRIMARY_THEME,
  toggleTheme: () => {},
});

export function useTheme() {
  return useContext(ThemeContext);
}

export const ThemeProvider = ({ children }) => {
  const { currentDomain } = useContext(NavigationContext);
  const router = useRouter();
  const [theme, setTheme] = useState(PRIMARY_THEME);

  const { locale: lang } = useRouter();

  useEffect(() => {
    const updateTheme = (newTheme, save = true) => {
      document.documentElement.setAttribute("data-theme", newTheme);
      if (save && typeof window !== "undefined" && window.localStorage) {
        window.localStorage.setItem("theme", newTheme);
      }
    };

    if (!is750ThemeEnabled(currentDomain, lang, router.asPath)) {
      // always set the theme to primary for this page
      // but don't update the theme in local storage
      setTheme(PRIMARY_THEME);
      updateTheme(PRIMARY_THEME, false);
      return;
    }

    const savedTheme = window.localStorage?.getItem("theme") || PRIMARY_THEME;
    setTheme(savedTheme);
    updateTheme(savedTheme);
  }, [currentDomain, router.asPath, lang]);

  const toggleTheme = useCallback(() => {
    if (!allowedLanguages.includes(lang)) {
      return;
    }

    const newTheme = theme === PRIMARY_THEME ? SECOND_THEME : PRIMARY_THEME;
    setTheme(newTheme);
    document.documentElement.setAttribute("data-theme", newTheme);
    if (typeof window !== "undefined") {
      window.localStorage.setItem("theme", newTheme);
    }
  }, [lang, theme]);

  const value = useMemo(
    () => ({
      theme,
      toggleTheme,
    }),
    [theme, toggleTheme],
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: node.isRequired,
};
